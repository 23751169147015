import { BrowserModule} from '@angular/platform-browser';
import {  BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 
import { HeaderComponent } from './shared/layout/header/header.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HomeComponent } from './shared/views/home/home.component';
import {HttpClientModule} from '@angular/common/http';
import { ProfileComponent } from './shared/views/profile/profile.component';
import { CourseComponent } from './shared/views/course/course.component'
import { OwlModule } from 'ngx-owl-carousel';
import { AuthGuard } from './shared/services/auth-guard';
import { AppConfig } from './app-config';
import { WelcomeComponent } from './shared/layout/welcome/welcome.component';
import { GuideComponent } from './shared/views/guide/guide.component';
import { FormsModule } from '@angular/forms';
import { TopupComponent } from './shared/layout/topup/topup.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MybooksComponent } from './shared/views/mybooks/mybooks.component';
import { IntroduceComponent } from './shared/views/introduce/introduce.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MybooksGridComponent } from './shared/views/mybooks-grid/mybooks-grid.component';
import { CourseGridComponent } from './shared/views/course-grid/course-grid.component'; 


@NgModule({
  declarations: [
    AppComponent ,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ProfileComponent,
    CourseComponent,
    WelcomeComponent,
    GuideComponent,
    TopupComponent,
    MybooksComponent,
    IntroduceComponent,
    MybooksGridComponent,
    CourseGridComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule ,
    HttpClientModule,
    OwlModule,
    FormsModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AngularFontAwesomeModule, 
  ],
  providers: [AuthGuard,AppConfig],
  bootstrap: [AppComponent]
})
export class AppModule { }
