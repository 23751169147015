import { Component, OnInit } from '@angular/core';
import { BookService } from '../../services/book.service';
import { CategoryService } from '../../services/category.service';
import { DownloadService } from '../../services/download.service';
import { AppConfig } from 'src/app/app-config';
import {MatSnackBar} from '@angular/material';

import { from } from 'rxjs';
@Component({
  selector: 'app-course',
  templateUrl: './course-grid.component.html',
  styleUrls: ['./course-grid.component.scss']
})
export class CourseGridComponent implements OnInit {

  constructor(private bookService: BookService, private cateService: CategoryService, private downloadService: DownloadService, private snackBar:MatSnackBar) { }


  ngOnInit() {
    this.getCategories()
  }

  public gradeLevel = {
    elementary: [],
    secondary: [],
    highschool: []
  }

  extraSlideOptions = {
    items: 3, dots: true, nav: true, margin: 30, responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      800: {
        items: 3
      },
      1000: {
        items: 3
      }
    }
  };

  public books: {
    elementary: Array<any>
    secondary: Array<any>
    highschool: Array<any>
    extra: Array<any>
  }

  getBookList() {
    let bookList = []
    this.books = {
      elementary: [],
      secondary: [],
      highschool: [],
      extra: [
        {
          "image": "ep.smiles-1-iebook.png", // neu ko cung domain voi trang nay thi can url tuyet doi!
          "title": "Smiles ieBook",
          "link": "https://drive.google.com/open?id=1gxmQo8P_1MfzroqcOCj3H_rXuv34KRly",
          "description": "Phần mềm tự học cho học sinh tiểu học"
        },
        {
          "image": "ep.spark-7-iwb.png",
          "title": "Spark 7 IWB",
          "link": "https://drive.google.com/open?id=1V3sQ25DJPiGjCyNoyUo_G1nT7lZs5b1B",
          "description": "Phần mềm Interactive White Board - Dạy học trên lớp cho giáo viên THCS"
        },
        {
          "image": "ep.smarttime-10-iwb.png",
          "title": "Smart Time IWB",
          "link": "https://drive.google.com/open?id=1mavRAgOIeeIfz-cLh5wOQB8I24DHeB3j",
          "description": "Phần mềm Interactive White Board - Dạy học trên lớp cho giáo viên THPT"
        },
        {
          "image": "ep.smiles-5-teacher-res.png",
          "title": "TRP 5",
          "link": "https://drive.google.com/open?id=1Ru7EhAcuQ6lbC9w4Cnbra-ZDd8Sb8rdl",
          "description": "Teacher's Resources Pack - Bộ đề thi mẫu cho giáo viên tiểu học"
        },
        {
          "image": "ep.spark-6-teacher-book.png",
          "title": "Teacher’s Book Spark 6",
          "link": "https://drive.google.com/open?id=18M7xok0HBesx7tOIKF26Aw8_3sdWAdEp",
          "description": "Sách giáo viên cho THCS"
        },
        {
          "image": "ep.smarttime-10-teacher-book.png",
          "title": "Teacher’s Book Smart Time 10",
          "link": "https://drive.google.com/open?id=15AFHHwLKDHHZ6pj2Q7PZpugrzOG6vaHi",
          "description": "Sách giáo viên cho THPT"
        },
        {
          "image": "smiles-poster-s.png",
          "title": "Brochure",
          "link": "https://drive.google.com/open?id=1VbjEA2t_ttdlISJvHYAiHjz9uyQk51-S",
          "description": "Tài liệu giới thiệu"
        },
        {
          "image": "spark-poster-s.png",
          "title": "Presentation",
          "link": "https://drive.google.com/open?id=1VbjEA2t_ttdlISJvHYAiHjz9uyQk51-S",
          "description": "Tài liệu giới thiệu dạng slide"
        },
        
      ]
    }
    this.bookService.getAllBookListApi().subscribe(data => {
      bookList = <Array<any>>data
      bookList.forEach(book => {
        this.categoriesArray.forEach(cate => {

          if (book['categoryId'] == cate['id']) {
            if (cate['code'] == 'elementary') {
              this.books.elementary.push(book)
            }

            if (cate['code'] == 'secondary') {
              this.books.secondary.push(book)
            }

            if (cate['code'] == 'highschool') {
              this.books.highschool.push(book)
            }

            // if (cate['code'] == '#') {
            //   this.books.extra.push(book)
            // }
          }

        })

        // if (!book['categoryId']) {
        //   this.books.extra.push(book)
        // }

      })
    })

  }


  private categoriesArray: Array<any>
  getCategories() {
    this.categoriesArray = []
    this.cateService.getCategory().subscribe(data => {
      let categories = <Array<any>>data
      categories.forEach(item => {
        if (item['code'].slice(5) > 0 && item['code'].slice(5) <= 5) {
          this.categoriesArray.push(
            {
              id: item['id'],
              code: 'elementary',
              name: 'Tiểu học'
            }
          )
        }

        if (item['code'].slice(5) > 5 && item['code'].slice(5) <= 9) {
          this.categoriesArray.push(
            {
              id: item['id'],
              code: 'secondary',
              name: 'Trung học cơ sở'
            }
          )
        }

        if (item['code'].slice(5) > 9 && item['code'].slice(5) <= 12) {
          this.categoriesArray.push(
            {
              id: item['id'],
              code: 'highschool',
              name: 'Trung học phổ thông'
            }
          )
        }

        if (item['code'] == '#') {
          this.categoriesArray.push(
            {
              id: item['id'],
              code: 'extra',
              name: 'Sách khác'
            }
          )
        }

      });

      this.getBookList();

    })
  }

  downloadBook(book) {
    if (book.link) {
      return window.location.href = this.downloadService.directExtraDownload(book.link)
    }
    else if (book.type == 'direct' && !book.link) {
      return window.location.href = this.downloadService.directDownload(book.code)
    } else {
      return this.downloadService.engineDownload(book.code).subscribe(url => {
        window.location.href = url
      })
    }
  }


  createDownloadLink(book) {
    if (book.link) {
      return book.link
    }

    if (book.type == 'direct' && !book.link) { 
      return this.downloadService.directDownload(book.code)

    } else { 
      return this.downloadService.engineDownloadLink(book.code)
    } 
  }
  

  setBookCover(bookCover){
    return `${AppConfig.Controller.imageCdn}${bookCover}.png`
  }
  
  checkValid(bookValid) {
    if (!bookValid) {
      this.snackBar.open("Bạn vui lòng nhập mã để có quyền truy cập",null,{
        verticalPosition:'top',
        horizontalPosition:'right',
        panelClass:['bg-danger','alert','text-white'],
        duration:2000
      })
    }

  }


}
