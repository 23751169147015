import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'site-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @HostListener('window:scroll', ['$event']) 
  scroll(event) {
    if (window.pageYOffset > 300) {
      this.goTopIcon ='show'
    } else {
      this.goTopIcon =''
    }
    
  }
  
  constructor() { }

  public goTopIcon =''
  ngOnInit() {
  }

  download(){
    let url ='https://storage.googleapis.com/mbbp/p/MyBestBook.NET.msi'
    window.location.href=url
  } 

  scrollToTop() {
    (function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
    })();
}

 
}
