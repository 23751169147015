import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app-config';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient, private appConfig:AppConfig) { }

  getCodeHistory() {
    return this.http.get(this.appConfig.createUrl(AppConfig.Controller.codeHistory),{
      headers:<HttpHeaders>this.appConfig.getHeader()
    })
  }
}
