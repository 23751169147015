import { Component } from '@angular/core'; 
// import { environment } from 'src/environments/environment';
// import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tienganh3s';
 
  ngOnInit(){
    // if (environment.production) {
    //   enableProdMode();
    // }
  }
}
