import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { AppConfig } from 'src/app/app-config';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient, private config:AppConfig) { }

  // createAuthorizationHeader() {
  //   let headers: HttpHeaders = new HttpHeaders()
  //   headers.append('Authorization', 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiVGhpZXAgSG_DoG5nIiwicm9sZXMiOnt9LCJzY29wZSI6bnVsbCwic2Vzc2lvbiI6IjVjYTJlNTIzNDk0MzBlMDAxZWYxYWI2YyIsInVzZXIiOiI1Y2ExZGZlNDQ5NDMwZTAwMWVmMWFiNTEiLCJleHBpcmVzIjoxNTU2NzcxMzYzODUyLCJpYXQiOjE1NTQxNzkzNjMsImV4cCI6MTU1Njc3MTM2M30.SkkKvuT4aubs9FJdXbOODkGxJByQi1XlYRTR1uvfiDU')
  //   headers.append( 'Content-Type', 'application/json')
  //   return headers
  // }


  getCategory() { 
    return this.http.get(this.config.createUrl(AppConfig.Controller.categoryList),
      {
        headers: <HttpHeaders> this.config.getHeader()
      })
    }

}
 
