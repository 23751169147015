import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app-config';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http:HttpClient, private appConfig:AppConfig) { }

  chargeCode(code) {
    return this.http.post(this.appConfig.createUrl(AppConfig.Controller.chargeCode),{
      code:code
    },{
      headers:<HttpHeaders>this.appConfig.getHeader()
    })
  }
}
