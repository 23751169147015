import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app-config';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http:HttpClient, private appConfig:AppConfig) { }


  directDownload(code) {
  return  this.appConfig.createUrl(`${AppConfig.Controller.downloadDirect}/${code}`)
  }

  directExtraDownload(link) {
    return this.appConfig.createExtraUrl(`${link}`)
  }

  engineDownload(code) {
    return this.http.get(
      this.appConfig.createUrl(`${AppConfig.Controller.downloadTools}?uri=`+ encodeURIComponent(`smarttime/${code}?token={TOKEN}`)),
      {
        headers: <HttpHeaders>this.appConfig.getHeader(), 
        withCredentials: true,
        responseType:'text'

    })
  }

  engineDownloadLink(code) {
    return this.appConfig.createUrl(`${AppConfig.Controller.downloadTools}?uri=`+ encodeURIComponent(`smarttime/${code}?token={TOKEN}`))
  }


}
