import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app-config';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(private http:HttpClient, private appConfig:AppConfig) { }

  getBookList() {
    return this.http.get(this.appConfig.createUrl(AppConfig.Controller.bookList),
      {
        headers: <HttpHeaders>this.appConfig.getHeader()
      })
  }

  getMyBookListApi() {
  return  this.http.get(this.appConfig.createUrlAPI(AppConfig.Controller.myBookList),
    {
      headers: <HttpHeaders>this.appConfig.getHeaderApi()
    })
  }

  getAllBookListApi() {
    return  this.http.get(this.appConfig.createUrlAPI(AppConfig.Controller.allBookList),
      {
        headers: <HttpHeaders>this.appConfig.getHeaderApi()
      })
    }
}
