import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CategoryService } from '../../services/category.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent implements OnInit {
  @ViewChild('closeAddExpenseModal') closeAddExpenseModal: ElementRef;

  constructor(private cateService:CategoryService) { }

  mySlideImages = [1,2,3,4,5,6,7,8,9].map((i)=> `https://picsum.photos/640/480?image=${i}`);
  // myCarouselImages =[1,2,3,4,5,6,7,8,9].map((i)=>`https://picsum.photos/640/480?image=${i}`);

  public books:Array<any> // Cach nao cung duoc books =[]
  public understandModal;

  mySlideOptions={items: 3, dots: true, nav: true, margin:30, 	responsive: {
  0: {
    items: 1
  },
  600: {
    items: 2
  },
  800: {
    items: 3
  },
  1000: {
    items: 4
  }
}};
myCarouselOptions={items: 9, dots: true, nav: true};



  ngOnInit() {
    // Khoi tao mockup data
    // Day la data mockup de gia lap tu api! Khong dung data nay o ben html duoc!
    this.books = [
      {
        "image": "ep.smiles-1-iebook.png", // neu ko cung domain voi trang nay thi can url tuyet dok!
        "title": "Smiles ieBook",
        "link": "https://drive.google.com/open?id=1gxmQo8P_1MfzroqcOCj3H_rXuv34KRly",
        "description": "Phần mềm tự học cho học sinh tiểu học"
      },
      {
        "image": "ep.spark-7-iwb.png",
        "title": "Spark 7 IWB",
        "link": "https://drive.google.com/open?id=1V3sQ25DJPiGjCyNoyUo_G1nT7lZs5b1B",
        "description": "Phần mềm Interactive White Board - Dạy học trên lớp cho giáo viên THCS"
      },
      {
        "image": "ep.smarttime-10-iwb.png",
        "title": "Smart Time IWB",
        "link": "https://drive.google.com/open?id=1mavRAgOIeeIfz-cLh5wOQB8I24DHeB3j",
        "description": "Phần mềm Interactive White Board - Dạy học trên lớp cho giáo viên THPT"
      },
      {
        "image": "ep.smiles-5-teacher-res.png",
        "title": "TRP 5",
        "link": "https://drive.google.com/open?id=1Ru7EhAcuQ6lbC9w4Cnbra-ZDd8Sb8rdl",
        "description": "Teacher's Resources Pack - Bộ đề thi mẫu cho giáo viên tiểu học"
      },
      {
        "image": "ep.spark-6-teacher-book.png",
        "title": "Teacher’s Book Spark 6",
        "link": "https://drive.google.com/open?id=18M7xok0HBesx7tOIKF26Aw8_3sdWAdEp",
        "description": "Sách giáo viên cho THCS"
      },
      {
        "image": "ep.smarttime-10-teacher-book.png",
        "title": "Teacher’s Book Smart Time 10",
        "link": "https://drive.google.com/open?id=15AFHHwLKDHHZ6pj2Q7PZpugrzOG6vaHi",
        "description": "Sách giáo viên cho THPT"
      },
      {
        "image": "smiles-poster-s.png",
        "title": "Brochure",
        "link": "https://drive.google.com/open?id=1VbjEA2t_ttdlISJvHYAiHjz9uyQk51-S",
        "description": "Tài liệu giới thiệu"
      },
      {
        "image": "spark-poster-s.png",
        "title": "Presentation",
        "link": "https://drive.google.com/open?id=1VbjEA2t_ttdlISJvHYAiHjz9uyQk51-S",
        "description": "Tài liệu giới thiệu dạng slide"
      },

    ]

    setTimeout(function() {
      if (localStorage.getItem("_ssn") && JSON.parse(localStorage.getItem("_ssn")).user <= 2581 && localStorage.getItem("closeModal") != "true") {
        document.getElementById("openModalButton").click();
      }
    }, 1000);
  }

  public closeModal() {
    if (this.understandModal) {
      localStorage.setItem("closeModal", "true");
    }
  }

}
