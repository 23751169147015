import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../services/subscription.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss']
})
export class TopupComponent implements OnInit {

  constructor(private subscriptionService:SubscriptionService, private snackbar:MatSnackBar) { }

  ngOnInit() {
  }

  public code:string
    topup(){
      if(!this.code) {
      return  this.snackbar.open('Vui lòng nhập mã ',null,{
          duration:2000,
          verticalPosition:'top',
          horizontalPosition:'right',
          panelClass:['alert','bg-danger','text-white']
        })
      }
    this.subscriptionService.chargeCode(this.code).subscribe(result=>{
      this.code=null
    return  this.snackbar.open("Đã nạp mã thành công!",null,{
        duration:2000,
        verticalPosition:'top',
        horizontalPosition:'right',
        panelClass:['alert','bg-success','text-white']
      })
    },err=>{ 
     // this.code=null
   return   this.snackbar.open(err['error'].error,null,{
        duration:2000,
        verticalPosition:'top',
        horizontalPosition:'right',
        panelClass:['alert','bg-danger','text-white']
      })
    })
  }

 

}
