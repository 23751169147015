import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "src/app/app-config";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private jwt = new JwtHelperService();
  constructor(
    private router: Router,
    private http: HttpClient,
    private appConfig: AppConfig
  ) {}

  // userInfo(token = null) {
  //   if (token) {
  //     return `${this.appConfig.createUrl(null)}web/user/avatar?token=${token}`;
  //   }
  //   let jwt: string;
  //   jwt = localStorage.getItem("_mif") || null;
  //   jwt = jwt && jwt != "undefined" ? JSON.parse(jwt) : null;
  //   if (jwt) {
  //     return `${this.appConfig.createUrl(null)}web/user/avatar?token=${
  //       jwt["token"]
  //     }`;
  //   }
  // }

  private getUserProfile(jwt) {
    return this.http.get(
      this.appConfig.createUrl(AppConfig.Controller.profile),
      {
        headers: <HttpHeaders>this.appConfig.getHeader(jwt)
      }
    );
  }

  private systemRegister(jwt) {
    return this.http.post(this.appConfig.createUrl("web/auth/session"), null, {
      headers: <HttpHeaders>this.appConfig.getHeader(jwt)
    });
  }

  loadProfile(jwt): any {

    let promise = new Promise((resolve,reject)=>{

      this.systemRegister(jwt).subscribe(result => {
        if (!result) {
          return reject(null);
        }

        this.getUserProfile(jwt).subscribe(profile => {
          if (!profile) {
            return reject(null);
          }

          let _userInfo = {
            ssn:result,
            usr: profile,
            tkn: result["_jwt"],
            upc: this.appConfig.createUrl('web/user/avatar?token=' + result["_jwt"])
          };

          if (this.saveProfile(_userInfo)) {
            return resolve(_userInfo);
          }
          return reject(null);
        });
      });
    })

    return promise;
  }

  authCheck(): string {
    let token = localStorage.getItem("_tkn");
    if (token) {
      return token;
    }
    return null;
  }

  private saveProfile(mif: object): boolean {
    localStorage.setItem("_mif", JSON.stringify(mif["usr"]));
    localStorage.setItem("_tkn", mif["tkn"]);
    localStorage.setItem("_upc", mif["upc"]);
    localStorage.setItem('_ssn', JSON.stringify(mif['ssn']))
    return true;
  }

  logout(): boolean {
    localStorage.clear();
    this.router.navigate(["/home"]);
    return true;
  }

  getProfile() {
    if (localStorage) {
      let upc = localStorage.getItem("_upc");
      let mif =     localStorage.getItem("_mif");
      let tkn =    localStorage.getItem("_tkn");
      let ssn =  localStorage.getItem('_ssn')

      if ( upc && mif && tkn && ssn) {
        return  {
          upc: upc,
          mif:JSON.parse(mif),
          tkn:tkn,
          ssn:JSON.parse(ssn)
        }
      }

      return null;
    }
  }

  setProfile(profile: any) {
    return this.http.post(
      this.appConfig.createUrl(AppConfig.Controller.profile),
      profile,
      {
        headers: <HttpHeaders>this.appConfig.getHeader()
      }
    );
  }

  private getGoogleUserInfo(token) {
    return this.http.get(
      `https://www.googleapis.com/admin/directory/v1/users/me/photos/thumbnail`,
      {
        headers: <HttpHeaders>this.appConfig.getHeader(token)
      }
    );
  }
}
