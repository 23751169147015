import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

 @Injectable()

 export class AuthGuard {

    constructor(private router: Router, private authService:AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 
        if (this.authService.authCheck()) {
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['home']);
        return false;
    }
 }