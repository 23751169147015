import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(private route:Router, private activateRoute: ActivatedRoute, private authService:AuthService) { }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(data=>{
      if (!data) {
      return   this.route.navigate(['/'])
      }
      if (!data['jwt']){
        return   this.route.navigate(['/'])
      }
 
      return   this.route.navigate(['bookstores'])
       
    })
    
  }

}
