import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { HomeComponent } from './shared/views/home/home.component';
import { ProfileComponent } from './shared/views/profile/profile.component';
import { CourseComponent } from './shared/views/course/course.component';
import { AuthGuard } from './shared/services/auth-guard';
import { WelcomeComponent } from './shared/layout/welcome/welcome.component';
import { GuideComponent } from './shared/views/guide/guide.component';
import { MybooksComponent } from './shared/views/mybooks/mybooks.component';
import { IntroduceComponent } from './shared/views/introduce/introduce.component';
import { MybooksGridComponent } from './shared/views/mybooks-grid/mybooks-grid.component';
import { CourseGridComponent } from './shared/views/course-grid/course-grid.component';

const routes: Routes = [
  {
    path:'', // Day la landing page, anh de la home, de gi cung dc.
    component:HomeComponent,
  },
  {
    path:'profile',
    component:ProfileComponent,
    pathMatch:'full'
  },
  {
    path:'welcome',
    component:WelcomeComponent,
    pathMatch:'full'
  },
  {
    path:'all-books', // Day la trang danh sach sach, phai dang nhap moi co. Path em dat cai gi thi dat, o day anh dang de la / tuc la index
    component: CourseGridComponent,
    canActivate:[AuthGuard],
    pathMatch:'full'
  },
  // {
  //   path:'my-books',
  //   component:MybooksComponent,
  //   canActivate:[AuthGuard],
  //   pathMatch:'full'
  // },
  {
    path:'my-books',
    component:MybooksGridComponent,
    canActivate:[AuthGuard],
    pathMatch:'full'
  },
  {
    path:'user-guide',
    component:IntroduceComponent,
    // canActivate:[AuthGuard],
    pathMatch:'full'
  },
  // {
  //   path:'guide',
  //   component:GuideComponent,
  //   pathMatch:'full'
  // },
  {path: '**',  redirectTo :'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled', // Add options right here
    // useHash:true Khong dung nua se bo dau #
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
