import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import {MatSnackBar} from '@angular/material'
import { ProfileService } from '../../services/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    private authService:AuthService,
    private snackBar: MatSnackBar,
    private profileService:ProfileService,
    private activateRoute: ActivatedRoute) {}

  public profiles
  public codeHistory: Array<any>
  public pageSize = 5
  public pageIndex = 1
  public pages = [5,10,20,50,100]
  public genders = [{"name": "Không", "value": ""}, {"name": "Nam", "value": "male"}, {"name": "Nữ", "value": "female"}]
  public pageItems =[]
  public readMore = true

  async ngOnInit() {
    this.profiles = {"name": ""};
    let token = localStorage.getItem('_tkn');
    let _profiles = await this.authService.loadProfile(token);
    this.profiles = _profiles.usr;
    this.profiles["avatar"] = _profiles.upc;
    this.getCodeHistory()
  }

  public setCode(code){
  return  code.replace(/(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})/, "$1-$2-$3-$4-$5")
  }

  public setPageSize(size) {
    this.pageSize = size;
    this.pageIndex=0;
    this.nextPage()
  }

  public getCodeHistory(){
    this.codeHistory =[]
    this.profileService.getCodeHistory().subscribe(codes=>{
      this.codeHistory = <Array<any>>codes
      this.pageItems = this.codeHistory.slice(0,this.pageIndex*this.pageSize)

    },err=>{
      console.log(err);
      this.snackBar.open(`Lỗi truy cập dữ liệu lịch sử sử dụng mã! (${err['error'].error.name})` ,null,{
        verticalPosition:'top',
        horizontalPosition:'right',
        duration:2000,
        panelClass:['bg-danger','text-white','alert']
      })
    })
  }


  public nextPage() {
    this.pageIndex++
    this.pageItems = this.codeHistory.slice(0,this.pageIndex*this.pageSize)
    if (this.codeHistory.length / (this.pageIndex*this.pageSize)  < 1 ){
      return this.readMore = false
    }
  }

  public showLess() {
    this.pageIndex=1
    this.readMore = true
    this.pageItems = this.codeHistory.slice(0,this.pageIndex*this.pageSize-1)
  }

  public saveProfile(){
    this.authService.setProfile(this.profiles).subscribe(data=>{
      this.snackBar.open("Cập nhật hồ sơ thành công!",null,{
        verticalPosition:'top',
        horizontalPosition:'right',
        duration:2000,
        panelClass:['bg-success','text-white','alert']
      })
    }, err=>{
      this.snackBar.open(`Cập nhật hồ sơ không thành công! (${err['error'].error.name})` ,null,{
        verticalPosition:'top',
        horizontalPosition:'right',
        duration:2000,
        panelClass:['bg-danger','text-white','alert']
      })
    })
  }

}
