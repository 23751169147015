import { Inject, Injectable } from '@angular/core';

@Injectable()
export class AppConfig {

    // private static apiURL = 'http://localhost:3000/'  // Endded by slash /
    // private static apiURLPHP = 'http://localhost:8000/'
    // private static apiURL = 'https://www.mybestbook.net/'  // Endded by slash /
    // private static apiURLPHP = 'https://tienganh3s.vn/'
    private static apiURL = 'https://apitest.tienganh3s.vn/'  // Endded by slash /
    private static apiURLPHP = '/';

    public static Controller = {
        categoryList: 'api/subscription/v1/category',
        myBookList: 'list_my_books.php',
        allBookList: 'list_all_books.php',
        bookList: 'api/subscription/v1/product/current/list',
        books: 'api/subscription/v1/book',
        profile: 'api/user/v1/user/profile',
        codeHistory:'api/subscription/v1/subscription/current',
        downloadDirect: 'web/book/v1/data/direct',
        downloadTools:'web/auth/to',
        chargeCode:'api/subscription/v1/subscription/current',
        authSignin: {
            google: {
                pub: 'pub/auth/login/google',
                cb: ''
            }
        },
        imageCdn:'https://tienganh3s.vn/3s/'
    }


    createUrl(componentAction) {
        if (!componentAction) {
            return AppConfig.apiURL;
        }

        let url = AppConfig.apiURL + componentAction;
        return url;
    }

    createExtraUrl(link) {
        return link
    }

    createUrlAPI(componentAction) {
        if (!componentAction) {
            return AppConfig.apiURLPHP;
        }

        let url = AppConfig.apiURLPHP + componentAction;
        return url;
    }

    public getHeaderApi(jwt = null): object {
        let token = jwt
        if (!token) {
            token = localStorage.getItem('_tkn')
        }
        return {
            'jwt': `${token}`,
            'Content-Type': "application/json"
        }
    }
    public getHeader(jwt = null): object {
        let token = jwt
        if (!token) {
            token = localStorage.getItem('_tkn')
        }
        return {
            'Authorization': `Bearer ${token}`,
            'Content-Type': "application/json"
        }
    }
}
