import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app/app-config';
import { AuthService } from '../../services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'site-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private activateRoute: ActivatedRoute,
    private local: Location,
    private appConfig: AppConfig,
  ) {

    this.activateRoute.queryParams.subscribe(data => {
      if (data) {
        if (data['jwt']) {
         this.authService.loadProfile(data['jwt']).then(prf=>{
          this.profile ={avatar:prf['upc'], email:prf['usr'].email,shortname: this.shortName(prf['usr'].name),username:prf['usr'].name}
           this.isLogOn = true;
         },err=>{

         })
          this.local.replaceState('')
        }
        else {
          if(this.authService.authCheck()) {
            this.isLogOn = true
            let _profile = this.authService.getProfile()
            this.profile = {
              avatar: _profile.upc,
              email:_profile.mif['email'],
              shortname: this.shortName(_profile.mif['name']),
              username:_profile.mif['name']
            }
          }
        }
      }
    })

  }

  public isLogOn: boolean = false

  ngOnInit() {

    if(this.authService.authCheck()) {
      this.isLogOn = true
      let _profile = this.authService.getProfile()

      this.profile = {
        avatar: _profile.upc,
        email:_profile.mif['email'],
        shortname: this.shortName(_profile.mif['name']),
        username:_profile.mif['name']
      }
    }

  }

  // download(){
  //   let url ='https://storage.googleapis.com/mbbp/p/MyBestBook.NET.msi'
  //   window.location.href=url
  // }

  public profile:{
      avatar: string
      username: string
      shortname: string
      email: string
  }



  SiginByGoogle() {
    // let url = 'https://www.mybestbook.net/pub/auth/login/google?callback=';
    // url += encodeURIComponent('https://www.mybestbook.net/loggedin?callback=' + encodeURIComponent(location.href))
    let url = this.appConfig.createUrl("") + 'users/auth/google_oauth2';
    return window.location.href = url
  }

  SignOut() {
    // this.router.navigate(['/'])
    this.authService.logout()
    this.isLogOn = false;
  }

  private shortName(name: string) {
    let _name = name.split(' ')
    let shortname = ''
    _name.forEach(item => {
      shortname += item.substr(0, 1)
    })
    return shortname
  }
}
